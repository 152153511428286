<template>
    <div class="grid justify-content-center">
        <div class="col-12 lg:col-6 text-center px-4 lg:px-0 lg:my-8">
            <h1 data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="300">ABOUT</h1>
            <p data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="300">Merupakan brand minuman kekinian dalam naungan PT. Keberkahan Tujuan Utama, ESQUE INDONESIA bergerak di bidang Food & Beverages. Berkomitmen untuk menebar kebahagiaan, manfaat, dan menginspirasi anak muda Indonesia dengan harapan menjadi pemuda yang bermanfaat dan menebar kebahagiaan bagi sesama.
<br>
<br>
Esque Indonesia mengusung konsep spiritual company dengan value live after life, yang bertujuan agar terbentuk lingkungan kerja yang sesuai dengan culture islami, dari sini terbentuk lah beberapa program pendukung, seperti Kajian, Tahsin al-Quran, Mabit (Malam bina iman dan Taqwa), OTW Ngopi (Orang Taqwa Ngobrol Perkara Iman), Esque Berbagi (berbagi nasi kotak setiap jumat), Esque Peduli dll. Selain itu ESQUE juga ada value growth and learning untuk meningkatkan hard skill maupun soft skill, dengan beragam program pengembangan seperti training, beasiswa, dan positive challenge.
<br>
<br>
Esque  pertama kali hadir di Yogyakarta pada tanggal 12 Juni 2019, hingga kini Esque memiliki 30+ outlet yang tersebar di wilayah DI Yogyakata, Jawa Tengah dan Jawa Timur (Yogyakarta, Semarang, Solo, Kudus, dan Malang). Esque akan terus mengembangkan timbul timbul ke seluruh Indonesia.
</p>
        </div>
    </div>
</template>

<style scoped>
    .grid {
        background: #00A6A9;
        color: white;
    }

    /* @media screen and (max-width: 480px) {
        p {
            font-size: 10px;
        }

        h1 {
            font-size: 20px;
        }
    } */
</style>

<script>
export default {
    mounted(){
        AOS.init();
    }
}
</script>