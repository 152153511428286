<template>
  <div class="login">
      <login-component />
  </div>
</template>

<script>
import LoginComponent from '@/components/LoginComponent.vue'
export default {
  components: { LoginComponent },
  
}
</script>